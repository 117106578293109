import { apiGateway } from '@va/api-client';
import { getConfig } from '@va/standalone/shared/api-client';
import { CreateFilterTemplatePayload, FilterTemplate, UpdateFilterTemplatePayload } from '@va/types/filter-templates';
import { toQueryString } from '@va/util/helpers';

export const createFilterTemplate = (websiteId: string, data: CreateFilterTemplatePayload) => {
  return apiGateway.post(`/v1/websites/${websiteId}/filters`, data, getConfig());
};

export const getFilterTemplates = async (websiteId: string, reportBlockId: string): Promise<FilterTemplate[]> => {
  const query = {
    reportBlockId,
  };
  const res = await apiGateway.get(`/v1/websites/${websiteId}/filters?${toQueryString(query)}`, getConfig());
  return res.data;
};

export const deleteFilterTemplates = (websiteId: string, ids: string[]) => {
  return apiGateway.delete(`/v1/websites/${websiteId}/filters`, { ids: ids }, getConfig());
};

export const updateFilterTemplate = (id: string, data: UpdateFilterTemplatePayload) => {
  return apiGateway.patch(`/v1/filters/${id}`, data, getConfig());
};
